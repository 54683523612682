<template>
  <router-link
    class="btn btn-lg btn-primary contact-us-cta"
    :to="{ name: routes.CONTACT_PAGE }"
  >
    <oc-content :item="$oc.content.sharedContactUsCtaLabel" />
  </router-link>
</template>

<script>
import { CONTACT_PAGE } from '../../router/routes.types'
export default {
  name: 'ContactUsBtn',
  data () {
    return {
      routes: {
        CONTACT_PAGE
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-us-cta {
  border-radius: 32px;
  filter: drop-shadow(0 0.5rem 0.5rem rgba(71, 227, 161, 0.5));
  font-family: $font-family-poppins;
  font-weight: 600;
  padding: 0.4rem 2rem;
  text-decoration: none;
}
</style>

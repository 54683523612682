<template>
  <resources-layout :page-data-loaded="pageDataLoaded">
    <template v-slot:title>
      <oc-content :item="$oc.content.providerResourcesPageTitle" />
    </template>
    <template v-slot:content>
      <oc-content :item="$oc.content.providerResourcesPageBody" />
    </template>
  </resources-layout>
</template>

<script>
import ResourcesLayout from './ResourcesLayout'
import { getOrchardCoreContentModule } from '../../lib/api'
import pageMixin from '../../lib/mixins/page'

export default {
  name: 'ProviderResources',
  components: {
    ResourcesLayout
  },
  mixins: [pageMixin],
  async mounted () {
    try {
      const response = await getOrchardCoreContentModule('provider-resources-page')
      this.$oc.setContent(response.data)
      this.pageDataLoaded = true
    } catch {
      this.pageDataLoaded = false
    }
  },
  metaInfo: {
    title: 'Benefit Buddy - Provider Resources'
  }
}
</script>

<style>

</style>

<template>
  <div class="container page-min-height resources-layout">
    <div
      v-if="pageDataLoaded === true"
      class="row"
    >
      <div class="col col-lg-6">
        <div class="resources-layout__wrapper mt-3">
          <h2 class="resources-layout__title mb-4">
            <slot name="title" />
          </h2>
          <div class="resources-layout__content">
            <slot name="content" />
          </div>
          <div class="resources-layout__cta-wrapper mb-4">
            <contact-us-cta />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactUsCta from '../../components/Shared/ContactUsCta'

export default {
  name: 'ResourcesLayout',
  components: {
    ContactUsCta
  },
  props: {
    pageDataLoaded: { type: Boolean, default: null, required: false }
  }
}
</script>

<style lang="scss" scoped>
.resources-layout {
  &__title {
    font-family: $font-family-poppins;
    font-size: 1.6rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  &__content {
    font-size: 0.9rem;
    line-height: 1.4rem;
    margin: 0 0 3rem 0;
    padding: 0;
  }

  &__cta-wrapper {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 2rem;

    &__wrapper {
      border: 1px solid #e9e9e9;
      border-radius: 10px;
      margin-bottom: 4rem;
      padding: 1rem;
      position: relative;
    }

    &__title {
      background-color: $white;
      padding: 0 1rem;
      position: absolute;
      top: -16px;
      left: 20px;
      text-align: left;
    }

    &__content {
      font-size: 1rem;
      margin: 0;
      padding: 1.6rem 1rem;
    }

    &__cta-wrapper {
      background-color: $white;
      position: absolute;
      bottom: -46px;
      left: 20px;
      padding: 0 1rem;
      text-align: left;
    }
  }
}
</style>
